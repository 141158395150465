import Header from "./header";
import Pet from "./pet";
import Screen from "./screen";
import { createContext, useState, useEffect } from "react";
import { ethers } from "ethers";

export const WalletContext = createContext(null);
const arbChainId = "0xa4b1";

const Contents = () => {
	/*Metamask API changes handler
  - Installed
  - Network
  - Account changes
  - Chain changed
  - Connection state changed
  */

	const [index, setIndex] = useState(0);

	const [connected, setConnected] = useState(false);
	const [arbitrum, setArbitrum] = useState(false);
	const [installed, setInstalled] = useState(false);
	const [deposit, setDeposit] = useState(true);
	const [loading, setLoading] = useState(false);
	const [account, setAccount] = useState("");
	const [textButton, setTextButton] = useState("Connect wallet");
	const [multiButtonText, setMultiButtonText] = useState("Connect wallet");
	const [provider, setProvider] = useState();
	const [updateInterval, setUpdateInterval] = useState(null);

	window.addEventListener("load", function () {
		if (!(window.ethereum && window.ethereum.isMetaMask)) {
			setInstalled(false);
			setTextButton("MetaMask not installed");
			setMultiButtonText("MetaMask not installed");
		} else {
			setInstalled(true);
			setTextButton("Connect wallet");
			setMultiButtonText("Connect wallet");
		}
	});

	const AccountChangedHandler = (accounts) => {
		let display;
    let newAcc = accounts[0];
		setAccount(newAcc);
		if (accounts.length > 0) {
			display =
				accounts[0].substring(0, 6) + "..." + accounts[0].substring(38);
			setTextButton(display);
			let aux = new ethers.providers.Web3Provider(
				window.ethereum
			).getSigner();
			setProvider(aux);
		} else {
			clearInterval(updateInterval);
			setUpdateInterval(null);
			setTextButton("Connect wallet");
			setMultiButtonText("Connect wallet");
			setConnected(false);
			setAccount("");
		}
	};

	useEffect(() => {
		window.ethereum?.on("accountsChanged", AccountChangedHandler);
		return () => {
			window.ethereum?.removeListener(
				"accountsChanged",
				AccountChangedHandler
			);
		};
	});

	const ChainChangedHandler = (chainId) => {
		if (connected) {
			let display;
			if (chainId === arbChainId) {
				setArbitrum(true);
				display =
					account.substring(0, 6) + "..." + account.substring(38);
				setTextButton(display);
				setMultiButtonText("Introduce amount");
			} else {
				setArbitrum(false);
				setTextButton("Change to ARB");
				setMultiButtonText("Change to ARB");
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		window.ethereum?.on("chainChanged", ChainChangedHandler);
		return () => {
			window.ethereum?.removeListener(
				"chainChanged",
				ChainChangedHandler
			);
		};
	});

	const ConnectWallet = async () => {
		window.ethereum
			.request({ method: "eth_requestAccounts" })
			.then(async (accounts) => {
				setConnected(true);

				await changeToArb();

				window.ethereum
					.request({ method: "eth_chainId" })
					.then((chainId) => {
						if (chainId === arbChainId) {
							let aux = new ethers.providers.Web3Provider(
								window.ethereum
							).getSigner();
							setProvider(aux);
							setArbitrum(true);
							AccountChangedHandler(accounts);
							setMultiButtonText("Introduce amount");
						} else {
							setArbitrum(false);
							setLoading(false);
						}
					});
			})
			.catch((error) => {
				// console.log(error.message);
			});
	};

	const addARBNetwork = async () => {
		try {
			await window.ethereum.request({
				method: "wallet_addEthereumChain",
				params: [
					{
						chainId: arbChainId,
						rpcUrls: ["https://arb1.arbitrum.io/rpc"],
						chainName: "Arbitrum",
						nativeCurrency: {
							name: "Ether",
							symbol: "ETH",
							decimals: 18,
						},
						blockExplorerUrls: ["https://arbiscan.io"],
					},
				],
			});
		} catch (error) {
			// console.log(error);
		}
	};

	const changeToArb = async () => {
		try {
			await window.ethereum.request({
				method: "wallet_switchEthereumChain",
				params: [{ chainId: "0xa4b1" }],
			});
		} catch (switchError) {
			if (switchError.code === 4902) {
				await addARBNetwork();
			} else {
				// console.log("Network didn't change");
				setMultiButtonText("Change to ARB");
				setTextButton("Change to ARB");
				setLoading(false);
			}
		}
	};

	return (
		<div className="Contents">
			<WalletContext.Provider
				value={{
					connected,
					setConnected,
					arbitrum,
					setArbitrum,
					installed,
					setInstalled,
					deposit,
					setDeposit,
					loading,
					setLoading,
					account,
					setAccount,
					textButton,
					setTextButton,
					multiButtonText,
					setMultiButtonText,
					provider,
					setProvider,
					updateInterval,
					setUpdateInterval,
				}}
			>
				<Header connect={ConnectWallet} arb={changeToArb} />
				<Screen connect={ConnectWallet} arb={changeToArb} />
				<Pet />
			</WalletContext.Provider>
		</div>
	);
};

export default Contents;
