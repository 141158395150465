import { useContext, useState } from "react";
import { WalletContext } from "./contents";

const Connect = ({ connect, arb }) => {
  //Top connect button and logic
  const {
    connected,
    setConnected,
    arbitrum,
    setArbitrum,
    installed,
    setInstalled,
    account,
    setAccount,
    textButton,
    setTextButton,
  } = useContext(WalletContext);

  const ButtonOnClick = () => {
    if (!installed) window.open("https://metamask.io/download/");
    else if (!connected) connect();
    else if (!arbitrum) arb();
  };

  let cursorType = textButton === "Connect wallet" ? "pointer" : "default";

  return (
    <div>
      <button
        style={{ width: "fit-content", cursor: cursorType}}
        className="Connect"
        onClick={ButtonOnClick}
        disabled={(connected && arbitrum)}
      >
        {textButton}
      </button>
    </div>
  );
};

export default Connect;
