/* global BigInt */
import Cartera from "./cartera";
import React, { useState, useContext, useCallback, useEffect } from "react";
import Selection from "./selection";
import { WalletContext } from "./contents";
import { ethers } from "ethers";

import Vault_ABI from "../ABI/GrowiHFVault.json";
import Token_ABI from "../ABI/GrowiHF.json";
import PaymentToken_ABI from "../ABI/USDC.json";
import Addresses from "../addr/addresses.json";

const BackgroundApp = ({ connect, arb }) => {
	//App logic
	const {
		account,
		connected,
		arbitrum,
		deposit,
		setDeposit,
		setMultiButtonText,
		loading,
		setLoading,
		provider,
		setProvider,
		updateInterval,
		setUpdateInterval,
	} = useContext(WalletContext);
	const [input, setInput] = useState("");
	const [output, setOutput] = useState("");
	const [qtyOp, setQtyOp] = useState("");
	const [priceValue, setPriceValue] = useState(1);

	const [paymentApproval, setPaymentApproval] = useState(0);
	const [gwhfApproval, setGwhfApproval] = useState(0);
	const [paymentBalance, setPaymentBalance] = useState(0);
	const [gwhfBalance, setGwhfBalance] = useState(0);

	const [gwhf_vault, set_gwhf_vault] = useState(null);
	const [gwhf_token, set_gwhf_token] = useState(null);
	const [payment_token, set_payment_token] = useState(null);

	useEffect(() => {
		if (provider !== undefined) {
			set_gwhf_vault(
				new ethers.Contract(
					ethers.utils.getAddress(Addresses["gwhf_vault_addr"]),
					Vault_ABI,
					provider
				)
			);

			set_gwhf_token(
				new ethers.Contract(
					ethers.utils.getAddress(Addresses["gwhf_token_addr"]),
					Token_ABI,
					provider
				)
			);

			set_payment_token(
				new ethers.Contract(
					ethers.utils.getAddress(Addresses["payment_token_addr"]),
					PaymentToken_ABI,
					provider
				)
			);
		}
	}, [provider]);

	useEffect(() => {
		if (window.ethereum && window.ethereum.isMetaMask) {
			let aux = new ethers.providers.Web3Provider(
				window.ethereum
			).getSigner();
			setProvider(aux); 
		}
	}, [account]);

	const update_Balance_and_Approval_and_Price = useCallback(async () => {
		try {
			setLoading(true);
			
			let res = [0, 0, 0, 0];
			res[0] = await gwhf_token.balanceOf(account);
			res[1] = await payment_token.balanceOf(account);
			res[2] = await gwhf_token.allowance(account, gwhf_vault.address);
			res[3] = await payment_token.allowance(account, gwhf_vault.address);

			let tokenSupply = Number(await gwhf_token.totalSupply());
			let tvl = await getHL_TVL();
			let price = (tvl * 1e18) / tokenSupply;
			setPriceValue(price);

			setGwhfBalance(BigInt(res[0]["_hex"]));
			setPaymentBalance(BigInt(res[1]["_hex"]));
			setGwhfApproval(BigInt(res[2]["_hex"]));
			setPaymentApproval(BigInt(res[3]["_hex"]));

			setLoading(false);
		} catch (error) {
			setLoading(false);
			clearInterval(updateInterval);
			setUpdateInterval(null);
		}
	}, [account]);

	const getHL_TVL = async () => {
		const uiEndpoint = "https://api-ui.hyperliquid.xyz/info";

		const payload = {
			type: "vaultDetails",
			user: ethers.utils.getAddress(Addresses["gwhf_eoa_addr"]),
			vaultAddress: ethers.utils.getAddress(Addresses["hl_vault_addr"]),
		};

		const options = {
			method: "POST",
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		};

		try {
			const response = await fetch(uiEndpoint, options);
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const jsonResponse = await response.json();
			const filtered = jsonResponse.followers.filter(
				(item) => item.user === "Leader"
			);

			return Number(filtered[0]["vaultEquity"]).toFixed(6);
		} catch (error) {
			console.error("Error fetching HL TVL");
		}
	};

	//Deposit-Withdraw switch
	const switchButton = () => {
		if (connected && arbitrum) {
			setDeposit(!deposit);
			setInput("");
			setOutput("");
			setMultiButtonText("Introduce amount");
		}
	};

	//Calculates output based on token price
	const calculateOutput = async (value) => {
		if (provider !== null) {
			setLoading(true);
			//On-chain token supply (divided by token decimals)
			let tokenSupply = Number(await gwhf_token.totalSupply());
			let tvl = await getHL_TVL();
			let price;
			if (tokenSupply === 0) price = 1;
			else {
				price = (tvl * 1e18) / tokenSupply;
			}
			setPriceValue(price);
			//Depending on deposit or withdraw we use price or 1/price
			if (deposit) {
				let roundedVal = (value / price).toFixed(4);
				roundedVal =
				roundedVal >= 1000000
				? Math.ceil(roundedVal)
				: roundedVal >= 1000
				? Number(roundedVal).toFixed(2)
				: roundedVal;
				setOutput(roundedVal);
			} else if (!deposit) {
				let roundedVal = (value * price).toFixed(4);
				roundedVal =
				roundedVal >= 1000000
				? Math.ceil(roundedVal)
				: roundedVal >= 1000
				? Number(roundedVal).toFixed(2)
				: roundedVal;
				setOutput(roundedVal);
			}
		}
		setLoading(false);
	};

	//Checks for network or connection changes and clears output and input
	useEffect(() => {
		if (!arbitrum || !connected) {
			setInput("");
			setOutput("");
			if (updateInterval !== null) {
				clearInterval(updateInterval);
				setUpdateInterval(null);
			}
		} else {
			if (updateInterval === null) {
				update_Balance_and_Approval_and_Price();
				let interval = setInterval(
					update_Balance_and_Approval_and_Price,
					10000
				);
				setUpdateInterval(interval);
			}
		}
	}, [arbitrum, connected, updateInterval]);

	//Checks for account changes and clears output and input
	useEffect(() => {
		setInput("");
		setOutput("");
		if (updateInterval !== null) {
			clearInterval(updateInterval);
			update_Balance_and_Approval_and_Price();
			let interval = setInterval(
				update_Balance_and_Approval_and_Price,
				10000
			);
			setUpdateInterval(interval);
		}
	}, [account]);
	//Checks for input changes and sets output amount accordingly
	//Also checks for balance and approval qty
	useEffect(() => {
		if (arbitrum && connected) {
			let value;
			try {
				value = Number(input);
				if (value !== Math.floor(value)) {
					value = Math.floor(value);
					setInput(value.toString());
				}
			} catch (error) {
				value = 0;
				setOutput("");
			}
			if (input !== "" && value !== 0) {
				if (deposit) {
					if (value >= 5) {
						try {
							if (BigInt(value * 1e6) <= paymentBalance) {
								if (BigInt(value * 1e6) <= paymentApproval) {
									calculateOutput(value);
									setMultiButtonText("Deposit");
									setQtyOp(BigInt(value * 1e6));
								} else {
									calculateOutput(value);
									setMultiButtonText("Approve USDC");
									setQtyOp(BigInt(value * 1e6));
								}
							} else {
								calculateOutput(value);
								setMultiButtonText("Insufficient balance");
								setQtyOp("");
							}
						} catch (error) {
							setInput("");
							setOutput("");
							setQtyOp("");
						}
					} else if (value < 0) {
						setMultiButtonText("Invalid quantity");
						setOutput("");
						setQtyOp("");
					} else {
						setMultiButtonText("Minumum deposit 5USDC");
						setOutput("");
						setQtyOp("");
					}
				} else if (!deposit) {
					let gwhf2usdc = value * priceValue;
					if (gwhf2usdc >= 2) {
						try {
							if (BigInt(value * 1e18) <= gwhfBalance) {
								if (BigInt(value * 1e18) <= gwhfApproval) {
									calculateOutput(value);
									setMultiButtonText("Withdraw");
									setQtyOp(BigInt(value * 1e18));
								} else {
									calculateOutput(value);
									setMultiButtonText("Approve GWHF");
									setQtyOp(BigInt(value * 1e18));
								}
							} else {
								calculateOutput(value);
								setMultiButtonText("Insufficient GWHF balance");
								setQtyOp("");
							}
						} catch (error) {
							setInput("");
							setOutput("");
							setQtyOp("");
						}
					} else if (gwhf2usdc < 0) {
						setMultiButtonText("Invalid quantity");
						setOutput("");
						setQtyOp("");
					} else {
						setMultiButtonText("Minumum withdraw is 2$ in GWHF");
						setOutput("");
						setQtyOp("");
					}
				}
			}
			else {
				setMultiButtonText("Introduce amount")
				setOutput("")
			}
		}
	}, [
		input,
		paymentApproval,
		gwhfApproval,
		paymentBalance,
		gwhfBalance,
		arbitrum,
		connected,
		deposit,
		setMultiButtonText,
		priceValue,
	]);

	return (
		<div className="BackgroundApp">
			{/* Input textarea */}
			<div className="Input">
				<input
					className="textarea"
					inputMode="decimal"
					type="number"
					placeholder="0"
					value={input}
					disabled={!connected || !arbitrum}
					onChange={(e) => {
						setInput(e.target.value);
					}}
				></input>
				<Selection gwhf={!deposit} />
			</div>
			{/* Alternate deposit-withdraw button*/}
			<div className="botonSwitch">
				<svg
					onClick={switchButton}
					className="switchSvg"
					viewBox="0 0 50 50"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="25" cy="25" r="25" fill="#F5F5F5" />
					<circle cx="25" cy="25" r="20" fill="#F0E400" />
					<path
						d="M31 21.3482L25.7395 15.5696C25.3938 15.1899 25.221 15 25 15C24.779 15 24.6062 15.1899 24.2605 15.5696L19 21.3482M19 27.7573L24.2605 33.5359C24.6062 33.9157 24.779 34.1055 25 34.1055C25.221 34.1055 25.3938 33.9157 25.7395 33.5359L31 27.7573"
						stroke="#262626"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
			{/* Output textarea */}
			<div className="Output">
				<input
					className="textarea2"
					inputMode="decimal"
					type="number"
					placeholder="0"
					disabled={true}
					value={output}
				></input>
				{loading && (
					<img
						className="loadingIcon"
						src="../../Imagenes/loading-gif.gif"
					/>
				)}
				<Selection gwhf={deposit} />
			</div>
			<div className="Price">
				{connected &&
					"1 GWHF = " + priceValue.toFixed(4).toString() + " USDC"}
			</div>
			{/* Wallet buttons logic*/}
			<Cartera
				connect={connect}
				arb={arb}
				qtyOp={qtyOp}
				input={input}
				recalcBalanceApproval={update_Balance_and_Approval_and_Price}
			/>
		</div>
	);
};

export default BackgroundApp;
